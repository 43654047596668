/* ⬇️boilerplate/misc⬇️ */
@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  box-sizing: border-box;
}

body,
html {
  overflow: auto;
  margin: auto;
  padding: 0;
  width: 100%;
  height: 100%;
  line-height:.9;
  scroll-behavior:smooth;
}

.App {
  width: 100%;
  height: 100%;
}



.bg-black-gradient {
  background: linear-gradient(112.1deg, #031326 11.4%, rgb(32, 38, 57) 70.2%);
}

/* In your style.css file */
.active .nav-indicator {
  width: 4rem; /* same as group-hover:w-16 */
  background-color: #e2e8f0; /* same as group-hover:bg-slate-200 */
}

.active .nav-text {
  color: #e2e8f0; /* same as group-hover:text-slate-200 */
}

.cursor-effect {
  pointer-events: none;
  position: fixed;
  width: 300%;
  height: 300%;
  margin-left: -50px;
  margin-top: -50px;
  border-radius: 50%;
  background: -webkit-radial-gradient(circle, rgba(0, 0, 0, 0) 0%, rgba(0, 188, 212, 0.1) 29%, rgba(0, 188, 212, 0.15) 50%, rgba(0, 188, 212, 0.15) 74%, rgba(0, 188, 212, 0.2) 100%); /* For older WebKit browsers */
  background: radial-gradient(circle, rgba(0, 0, 0, 0) 0%, rgba(0, 188, 212, 0.1) 29%, rgba(0, 188, 212, 0.15) 50%, rgba(0, 188, 212, 0.15) 74%, rgba(0, 188, 212, 0.2) 100%);
  transform: translate(-50%, -50%);
  z-index: 3; 
  opacity: 1!important;
}

